import React from "react";

import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./PageHeader.module.scss";
import "./PageHeader.scss";

import { ArrowRight20 } from "@carbon/icons-react";
import { QuadrantPlot20 } from "@carbon/icons-react";
import { Certificate20 } from "@carbon/icons-react";
import { Phone20 } from "@carbon/icons-react";

import SVGBanner from "../../../images/banner.svg";

const PageHeader = ({ title, theme, tabs = [] }) => (
  <div
    className={cx(styles.pageHeader, {
      [styles.withTabs]: tabs.length,
      [styles.darkMode]: theme === "dark",
    })}
  >
    <div className="bx--grid">
      <div className="bx--row">
        <div className="bx--col-lg-12">
          <h1 id="page-title" className={styles.text}>
            {title}
          </h1>
        </div>
      </div>
    </div>
  </div>
);

PageHeader.propTypes = {
  /**
   * Specify the title for the page
   */
  title: PropTypes.node,
};

function ServicesHeader({ buttons, copy, title, data }) {
  title = "Magnus Code Services";
  copy = "Learn more about our most popular services";
  buttons = [
    {
      copy: "Web Design and Development",
      renderIcon: ArrowRight20,
      href: "services/web-design",
    },
    {
      copy: "Real Time Telemetry (IoT)",
      renderIcon: ArrowRight20,
      href: "services/automation-iot",
    },
  ];

  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Magnus Code Services"
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  <ArrowRight20 class="bx--btn__icon" />
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function ContactHeader({ buttons, copy, title, data }) {
  title = "Contact";
  copy = "Get in touch. Prefer to call or send us an email instead?";
  buttons = [
    {
      copy: "Give Us a Phone Call",
      renderIcon: ArrowRight20,
      href: "tel:+60178725440",
    },
    {
      copy: "Email Us",
      renderIcon: ArrowRight20,
      href: "mailto:info@magnuscode.com",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Magnus Code: Subang Jaya, Malaysia"
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <Phone20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function IOTHeader({ buttons, copy, title, data }) {
  title = "Industrial Automation & IoT";
  copy = "Real time monitoring with Magnus IoT Edge™";
  buttons = [
    {
      copy: "Magnus IoT Edge™",
      renderIcon: QuadrantPlot20,
      href: "/contact",
    },
    {
      copy: "Contact Us",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="IOT and industrial automation in Malaysia"
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <QuadrantPlot20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function WorkHeader({ buttons, copy, title, data }) {
  title = "Work";
  copy = "List of our work, clients and partners.";
  buttons = [
    {
      copy: "Check out our gallery",
      renderIcon: ArrowRight20,
      href: "/gallery",
    },
    {
      copy: "Contact Us",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Work from clients in Malaysia and United States"
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function WebDevHeader({ buttons, copy, title, data }) {
  title = "Web Design and Development";
  copy =
    "We design beautiful and user friendly web applications with performance in mind.";
  buttons = [
    {
      copy: "Get a Quote",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
    {
      copy: "Check out our gallery",
      renderIcon: ArrowRight20,
      href: "/gallery",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Web design service in Malaysia"
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function GalleryHeader({ buttons, copy, title, data }) {
  title = "Gallery";
  copy = "Internal and external projects showcase.";
  buttons = [
    {
      copy: "Talk to our team",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
    {
      copy: "Check out our customers",
      renderIcon: ArrowRight20,
      href: "/work",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Gallery and images showcase from clients across Malaysia and United States"
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function MobileApp({ buttons, copy, title, data }) {
  title = "Mobile Applications";
  copy = "Experts in creating beautiful and user friendly mobile applications.";
  buttons = [
    {
      copy: "Check out our gallery",
      renderIcon: ArrowRight20,
      href: "/gallery",
    },
    {
      copy: "Talk to our team",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Mobile app development Malaysia"
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function MechanicalDesign({ buttons, copy, title, data }) {
  title = "Mechanical Design";
  copy = "Certified mechanical design professional.";
  buttons = [
    {
      copy: "View our certifications",
      renderIcon: ArrowRight20,
      href: "/certifications",
    },
    {
      copy: "Contact Us",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Mechanical design, fabrication and drawings. SolidWorks, CAD, Malaysia."
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <Certificate20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function ProductVisualization({ buttons, copy, title, data }) {
  title = "Product Visualization";
  copy = "Lighting and materials expert.";
  buttons = [
    {
      copy: "Check out our gallery",
      renderIcon: ArrowRight20,
      href: "/gallery",
    },
    {
      copy: "Contact Us",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Product visualization and renders by Magnus Code. Malaysian design agency."
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function Certifications({ buttons, copy, title, data }) {
  title = "Certifications";
  copy = "Take a look at some of our technical certifications.";
  buttons = [
    {
      copy: "Talk to our team",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
    {
      copy: "Check out our customers",
      renderIcon: ArrowRight20,
      href: "/work",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Certified mechanical design expert in Malaysia."
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function LogoDesign({ buttons, copy, title, data }) {
  title = "Logo Design";
  copy = "Professional logo design service and brand identity.";
  buttons = [
    {
      copy: "Check out our customers",
      renderIcon: ArrowRight20,
      href: "/work",
    },
    {
      copy: "Talk to our team",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Certified mechanical design expert in Malaysia."
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function Gameassets({ buttons, copy, title, data }) {
  title = "Game Assets";
  copy =
    "Game assets services for your virtual platform, summits, or conferences.";
  buttons = [
    {
      copy: "Check out our work",
      renderIcon: ArrowRight20,
      href: "/gallery",
    },
    {
      copy: "Talk to our team",
      renderIcon: ArrowRight20,
      href: "/contact",
    },
  ];
  return (
    <div class="magnus-lead-space">
      <Img
        fluid={data.childrenImageSharp[0].fluid}
        alt="Certified mechanical design expert in Malaysia."
      />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <ArrowRight20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
function Blank({ buttons, copy, title }) {
  copy = "";
  buttons = [];
  return (
    <div class="magnus-lead-space">
      <img src={SVGBanner} alt="Magnus Code Sdn. Bhd." />
      <div className="overlay"></div>
      <h1 class="bx--leadspace__title">{title}</h1>

      <div class="content">
        <p class="bx--leadspace__desc">{copy}</p>

        <ol class="bx--buttongroup">
          {buttons.map((button, key) => {
            return (
              <li key={key} class="bx--buttongroup-item">
                <a
                  tabindex="0"
                  class={
                    key === buttons.length - 1
                      ? "bx--btn bx--btn--primary"
                      : "bx--btn bx--btn--tertiary"
                  }
                  href={button.href}
                >
                  {button.copy}
                  {key === buttons.length - 1 ? (
                    <ArrowRight20 class="bx--btn__icon" />
                  ) : (
                    <QuadrantPlot20 class="bx--btn__icon" />
                  )}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}

function CustomPageHeader(props) {
  const data = useStaticQuery(graphql`
    query PageHeaderQuery {
      service: file(relativePath: { eq: "banner/room-white.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      contact: file(relativePath: { eq: "banner/location_map.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      web: file(relativePath: { eq: "banner/services-website-banner.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      iot: file(relativePath: { eq: "banner/iot-banner.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      work: file(relativePath: { eq: "banner/interior_render.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      certification: file(relativePath: { eq: "banner/pen.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "banner/mobile-app.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      mech: file(relativePath: { eq: "banner/mechanical-design.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      veritank: file(relativePath: { eq: "banner/veritank.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      logo: file(relativePath: { eq: "banner/magnus-good.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      game: file(relativePath: { eq: "banner/xbox.jpg" }) {
        id
        childrenImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  `);

  if (props.title === "Services - IT, Mechanical Design and Automation") {
    return <ServicesHeader {...props} data={data.service} />;
  } else if (props.title === "Contact") {
    return <ContactHeader {...props} data={data.contact} />;
  } else if (props.title === "Web Design and Development") {
    return <WebDevHeader {...props} data={data.web} />;
  } else if (props.title === "Industrial Automation & IoT") {
    return <IOTHeader {...props} data={data.iot} />;
  } else if (props.title === "Magnus Code work and list of clients") {
    return <WorkHeader {...props} data={data.mobile} />;
  } else if (props.title === "Certifications") {
    return <Certifications {...props} data={data.veritank} />;
  } else if (props.title === "Mobile Applications") {
    return <MobileApp {...props} data={data.mobile} />;
  } else if (props.title === "Product Visualization") {
    return <ProductVisualization {...props} data={data.work} />;
  } else if (props.title === "Mechanical Design") {
    return <MechanicalDesign {...props} data={data.mech} />;
  } else if (props.title === "Logo Design") {
    return <LogoDesign {...props} data={data.logo} />;
  } else if (props.title === "Game assets") {
    return <Gameassets {...props} data={data.game} />;
  } else if (
    props.title ===
    "Gallery showcase for products and services for our customers"
  ) {
    return <GalleryHeader {...props} data={data.work} />;
  } else {
    return <PageHeader {...props} />;
  }
}
export default CustomPageHeader;
